import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Gallery = ({ data }) => {
  return (
    <div className="container section-gallery" id="gallery">
      <span className="section-title">Galeri</span>
      <SimpleReactLightbox>
        <SRLWrapper>
          {data.galeri.map((img) => (
            <a key={img.image.id} href={img.image.sizes["1536x1536"]}>
              <img src={img.image.sizes["large"]} alt={img.title} />
              <div className="title">{img.title}</div>
            </a>
          ))}
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export default Gallery;
