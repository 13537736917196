import { SRLWrapper } from "simple-react-lightbox";

function LightBox({ images, caption }) {
  const elements = images.map((img) => ({
    src: img.sizes["1536x1536"],
    caption: caption,
    thumbnail: img.sizes.medium_large,
  }));

  return <SRLWrapper elements={elements} />;
}

export default LightBox;
