import React, { useState } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import LightBox from "./LightBox";
import OpenButton from "./OpenButton";

const Gallery = ({ data = [], loading }) => {
  const [images, setImages] = useState([]);
  const [caption, setCaption] = useState("");
  // const { openLightbox, closeLightbox } = useLightbox();
  return (
    <div className="container section-gallery" id="gallery">
      <span className="section-title">Proyek Terbaru</span>
      {loading ? (
        "loading"
      ) : (
        <div>
          <SimpleReactLightbox>
            <OpenButton
              data={data}
              setImages={setImages}
              setCaption={setCaption}
            />

            <LightBox images={images} caption={caption} />
          </SimpleReactLightbox>
        </div>
      )}
    </div>
  );
};

export default Gallery;
