import { useLightbox } from "simple-react-lightbox";
import Button from "../shared/Button";

export default function OpenButton({ data, setImages, setCaption }) {
  const { openLightbox } = useLightbox();

  return (
    <div className="interior-galeri-grid">
      {data.map((interior) => (
        <button
          key={interior.id}
          onClick={() => {
            setImages(interior.acf.galeri);
            setCaption(interior.acf.nama);
            setTimeout(() => {
              openLightbox();
            }, 100);
          }}
          className="open-image-button"
        >
          <img src={interior.acf.galeri[0].sizes["large"]} alt={"yuhuu"} />
          <div className="image-info">
            <div className="title">{interior.acf.nama}</div>
            <div className="desc">{interior.acf.deskripsi}</div>
            <Button variant="secondary" label="Lihat Detail" />
          </div>
        </button>
      ))}
    </div>
  );
}
