import React from "react";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import getProyek from "../api/get-proyek";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Interior from "../components/Interior/Interior";
import Navbar from "../components/Navbar";
import ProyekCard from "../components/proyek/ProyekCard";
import Offer from "../components/shared/Offer";
import ProyekLoading from "../components/shared/ProyekLoading";

const Proyek = () => {
  const router = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [proyek, setProyek] = useState([]);
  const name = router.params.name;

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = "Bisnis | Bumi Agung Podomoro";
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getProyek();

      setProyek(data.data);
      setLoading(false);
    }
  }, []);

  console.log(router);

  return (
    <div>
      <Navbar />
      {name === "proyek" && (
        <Header
          prefix="Lini Bisnis"
          title="Developer Perumahan"
          back="Kembali ke Bisnis"
          backLink={"/bisnis"}
        />
      )}
      {name === "proyek" ? (
        <>
          {loading ? (
            <ProyekLoading />
          ) : (
            <div className="konten-wrapper">
              {proyek.map((data) => (
                <ProyekCard key={data.id} proyek={data} />
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <Interior />
        </>
      )}
      <Offer />
      <Footer />
    </div>
  );
};

export default Proyek;
