import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./page/Home";
import Kontak from "./page/Kontak";
import Proyek from "./page/Proyek";
import DetailProyek from "./page/DetailProyek";
import Perusahaan from "./page/Perusahaan";
import Bisnis from "./page/Bisnis";
// import ContactUs from "./page/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/bisnis" component={Bisnis} exact />
        <Route path="/bisnis/:name" component={Proyek} exact />
        <Route path="/bisnis/:name/:id" component={DetailProyek} exact />
        <Route path="/kontak" component={Kontak} exact />
        <Route path="/perusahaan" component={Perusahaan} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
