import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import SimpleReactLightbox from "simple-react-lightbox";
import "swiper/swiper-bundle.min.css";
import "animate.css";

import "./assets/styles/styles.scss";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
