import React from "react";
import { useEffect, useState } from "react";
import getBisnis from "../api/get-bisnis";
import BisnisCard from "../components/bisnis/BisnisCard";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Offer from "../components/shared/Offer";
import ProyekLoading from "../components/shared/ProyekLoading";

const Bisnis = () => {
  const [loading, setLoading] = useState(false);
  const [bisnis, setBisnis] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = "Bisnis | Bumi Agung Podomoro";
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getBisnis();

      setBisnis(data.data);
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Header prefix="Lini Bisnis" title="PT. Bumi Agung Podomoro" />
      <div className="konten-wrapper">
        {loading ? (
          <ProyekLoading />
        ) : (
          <div className="container">
            <div className="bisnis">
              {bisnis.map((data) => (
                <BisnisCard key={data.id} slug={data.slug} data={data.acf} />
              ))}
            </div>
          </div>
        )}
      </div>
      <Offer />
      <Footer />
    </div>
  );
};

export default Bisnis;
